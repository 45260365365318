@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
  --nextui-colors-background: #0a0a0a;
  --nextui-colors-foreground: #ffffff;
}

.bg-background {
  background-color: #0a0a0a;
}

.text-foreground {
  color: #ffffff;
}

.text-primary {
  color: #2563eb;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

/* Targeting directly with CSS if Tailwind classes don't apply as expected */
.nextui-input-label {
  color: #3182ce; /* This is Tailwind's blue-500 color */
}